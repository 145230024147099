<template>
  <div class="topmargin">
    <ListEspiral />
  </div>
</template>

<script>
// @ is an alias to /src
import ListEspiral from "@/components/ListEspiral.vue";

export default {
  name: "EspiralView",
  components: {
    ListEspiral,
  },
};
</script>

<style>
</style>