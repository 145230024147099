<template>
  <div class="container text-center topmargin">
    <h1>Papel Bond</h1>
    <h6>El valor de los productos publicados NO tiene incluido el despacho.</h6>
    <h6>El peso máximo para compras por Mercado Libre es de 20 Kg.</h6>
    <div class="card-wrap">
      <article class="card" v-for="(papel, id) in papel" v-bind:key="id">
        <header class="header-card">
          <img v-bind:src="'img/bond/' + papel.img" />
        </header>
        <footer class="footer-card">
          <div class="categoria-card">
            <div class="texto-card tipo">
              <h1>{{ papel.idgramaje }} {{ papel.formato }}</h1>
              <h3>{{ papel.tipo }}</h3>
            </div>
            <div class="detalle">
              <h6>{{ papel.comment }}</h6>
            </div>
            <div class="text-left">
              <h6>Peso : {{ papel.peso }}</h6>
              <h6>Tamaño : {{ papel.tamaño }}</h6>
              <h5>Valor : {{ papel.precio }}</h5>
            </div>
            <button class="button-card-mercado">
              <a class="color" :href="papel.url" target="_blank"> Comprar </a>
            </button>             
          </div>
        </footer>
      </article>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "PapelLista",

  computed: {
    ...mapState(["papel"]),
  },
};
</script>

<style>
.detalle {
  height: 170px;
  margin-top: 20px;
}
.tipo {
  height: 125px;
}

.button-card-mercado{
  font-size: 125%;
  margin-top: 10px;
  border-radius: 25px;
  background: #f3df62;
  color:rgb(88, 85, 85);
  margin-bottom: 15px;
  height: 40px;
  width: 100px;
}
</style>
