<template>
  <div>
    <PapelLista />
  </div>
</template>

<script>
import PapelLista from "../components/PapelLista.vue";
// @ is an alias to /src

export default {
  name: "PapelView",
  components: {
    PapelLista,
  },
   mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
h1 {
  padding-top: 10px;
}
</style>
