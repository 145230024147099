<template>
  <div class="container topmargin">
    <PaginaInicio />
  </div>
</template>

<script>
import PaginaInicio from "../components/PaginaInicio.vue";

export default {
  name: "HomeView",
  components: {
    PaginaInicio,
  },
};
</script>
