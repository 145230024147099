<template>
  <div class="Container contacto text-center">
    <h1>¿Quieres hacer una compra directa?</h1>
    <h2>contactate con nosotros</h2>
    <button class="button-contacto" @click="contacto">Contacto</button>
  </div>
</template>

<script>
export default {
  name: "ContactoLink",

  methods: {
    contacto() {
      this.$router.push(`/contacto`);
    },
  },
};
</script>

<style>
.contacto {
  margin-top: 10px;
  margin-bottom: 30px;
}

.button-contacto {
  width: 300px;
  height: 50px;
  border-radius: 25px;
  font-size: 150%;
  margin: 10px 10px;
}

@media (max-width: 576px) {
  .contacto h1 {
    font-size: 125%;
  }
  .contacto h2 {
    font-size: 125%;
  }
  .button-contacto {
    width: 200px;
    height:100%;
    font-size: 100%;
  }
}

@media (min-width: 576px) {
  .contacto h1 {
    font-size: 125%;
  }
  .contacto h2 {
    font-size: 125%;
  }
  .button-contacto {
    width: 200px;
    height: 100%;
    font-size: 100%;
  }
}

@media (min-width: 768px) {
  .contacto h1 {
    font-size: 150%;
  }
  .contacto h2 {
    font-size: 150%;
  }
  .button-contacto {
    width: 200px;
    height: 40px;
    border-radius: 25px;
    font-size: 120%;
    margin: 5px 5px;
  }
}

@media (min-width: 992px) {
  .contacto h1 {
    font-size: 175%;
  }
  .contacto h2 {
    font-size: 175%;
  }
  .button-contacto {
    width: 300px;
    height: 50px;
    border-radius: 25px;
    font-size: 150%;
    margin: 10px 10px;
  }
}

@media (min-width: 1200px) {
  .contacto h1 {
    font-size: 175%;
  }
  .contacto h2 {
    font-size: 175%;
  }
  .button-contacto {
    width: 300px;
    height: 50px;
    border-radius: 25px;
    font-size: 150%;
    margin: 10px 10px;
  }
}
</style>