<template>
  <div class=" container topmargin">
    <ContactoPagina />
  </div>
</template>

<script>
import ContactoPagina from '../components/ContactoPagina.vue'


export default {
  name: "ContactoView",
  components: {
    ContactoPagina
  },
    mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>

</style>