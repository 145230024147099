<template>
  <div class="container text-center topmargin">
    <h1>Espiral</h1>    
    <h6>El valor de los productos publicados NO tiene incluido el despacho.</h6>
    <h6>El peso máximo para compras por Mercado Libre es de 20 Kg.</h6>
    <div class="card-wrap">
      <article class="card" v-for="(medida, id) in medida" v-bind:key="id">
        <header class="header-card">
          <img
            v-bind:src="'img/espiral/' + medida.img"
            class="card-img-top"
            alt="medidas"
          />
        </header>
        <footer class="footer-card">
          <div class="categoria-card">
            <div class="texto-card">
              <h3>{{ medida.medida }}</h3>
              <h6 class="text-h6">{{ medida.comment }}</h6>
            </div>
            <button class="button-espiral" @click="color(medida.idmedida)">
              Colores Disponibles
            </button>
          </div>
        </footer>
      </article>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ListEspiral",
  data() {
    return {};
  },

  computed: {
    ...mapState(["medida"]),
  },
  methods: {
    color(id) {
      let idmedida = id;
      this.$router.push(`/espiralcolor/${idmedida}`);
    },
  },
   mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
.texto-card {
  margin-top: 30px;
  height: 170px;
}
.text-h6 {
  font-size: 95%;
}
.button-espiral {
  font-size: 125%;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 25px;
  background: #979595;
  color: rgb(255, 255, 255);
  height: 50px;
  width: 200px;
}
</style>
