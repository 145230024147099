<template>
  <div>
    <ColorEspiral />
  </div>
</template>

<script>
import ColorEspiral from "../components/ColorEspiral.vue";
export default {
  name: "EspiralColor",

  components: {
    ColorEspiral,
  },
   mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style>
</style>