<template>
  <div class="container text-center topmargin">
    <h1>Espiral {{ $route.params.id }} mm</h1>
    <div class="card-wrap">
      <article
        class="card"
        v-for="(espiral, index) in filtroArray"
        :key="index"
      >
        <header class="header-card">
          <img
            v-bind:src="'../img/espiral/' + espiral.img"
            class="card-img-top"
            alt="color-espiral"
            
          />
        </header>
        <footer class="footer-card">
          <div class="categoria-card">           
            <div class="texto-card">
              <h3>{{ espiral.color }}</h3>
              <h6>{{ espiral.medida }}</h6>
              <hr>
              <h6>Peso Producto : {{espiral.peso}} </h6>
              <h4>Precio : {{ espiral.precio }}</h4>
            </div>
            <button class="button-card-mercado">
              <a class="color" :href="espiral.url" target="_blank"> Comprar </a>
            </button>
          </div>
        </footer>
      </article>
    </div>
  </div>
</template>


<script>
import { mapState } from "vuex";

export default {
  name: "ListEspiral",
  data() {
    return {};
  },
  methods: {},
  computed: {
    ...mapState(["espiral"]),
    filtroArray() {
      const numero = this.$route.params.id;
      return this.espiral.filter((espiral) => espiral.idmedida == numero);
    },

  },

};
</script>

<style>
.button-card-mercado{
  font-size: 125%;
  margin-top: 10px;
  border-radius: 15%;
  background: #f3df62;
  color:rgb(88, 85, 85);
  margin-bottom: 15px;
  height: 40px;
  width: 100px;
}

</style>