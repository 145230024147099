<template>
  <div class="container">
    <div class="container text-center topmargin">
      <h1>¿Que estas buscando?</h1>
      <div class="card-wrap-2">
        <article class="card-2">
          <header class="header-card-2">
            <img src="img/portada/espiral-estante.jpg" />
          </header>
          <footer class="footer-card-2">
            <div class="categoria-card-2">
              <div class="texto-card tipo">
                <h1>Espiral</h1>
                <button class="button-principal" @click="espiral">Espiral</button>
              </div>
            </div>
          </footer>
        </article>
        <div class="card-wrap-2">
          <article class="card-2">
            <header class="header-card-2">
              <img src="img/portada/papel-estante.jpg" />
            </header>
            <footer class="footer-card-2">
              <div class="categoria-card-2">
                <div class="texto-card tipo">
                  <h1>Papel</h1>
                  <button class="button-principal" @click="papel">Papel</button>
                </div>
              </div>
            </footer>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetalleProducto",
  methods: {
    espiral() {
      this.$router.push(`/espiral`);
    },
    papel() {
      this.$router.push(`/papel`);
    },
  },
};
</script>

<style>
img {
  max-width: 100%;
}

.categoria-card-2 {
  margin-top: 1rem;
}
.categoria-card-2 span {
  background: #25c4d8;
  color: #fff;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
}
.card-2 footer {
  padding: 0 1rem;
}
.button-card-2 {
  text-align: center;
  margin: 1rem 1rem 1rem 0;
}

.card-wrap-2 {
  display: flex;
  flex-flow: wrap;
}
.topmargin-2 {
  margin-top: 70px;
}

@media (max-width: 576px) {
  article.card-2 {
    max-width: 350px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
  .header-card-2 img {
    border-radius: 8px 8px 0 0;
    max-height: 350px;
    height: 350px;
    width: 100%;
  }
}

@media (min-width: 768px) {
  article.card-2 {
    max-width: 250px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 2rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
  .header-card-2 img {
    border-radius: 8px 8px 0 0;
    max-height: 250px;
    height: 250px;
    width: 100%;
  }
}

@media (min-width: 992px) {
  article.card-2 {
    max-width: 400px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 1rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
  .header-card-2 img {
    border-radius: 8px 8px 0 0;
    max-height: 400px;
    height: 400px;
    width: 100%;
  }
}

@media (min-width: 1200px) {
  article.card-2 {
    max-width: 400px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 3rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
  .header-card-2 img {
    border-radius: 8px 8px 0 0;
    max-height:400px;
    height: 400px;
    width: 100%;
  }
}


@media (min-width: 1300px) {
  article.card-2 {
    max-width: 500px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 4rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
  .header-card-2 img {
    border-radius: 8px 8px 0 0;
    max-height:500px;
    height: 500px;
    width: 100%;
  }
}


@media (min-width: 1400px) {
  article.card-2 {
    max-width: 450px;
    background: #f8f8f8;
    border-radius: 8px;
    margin: 2rem;
    box-shadow: 0 0 12px #d7d7d7;
  }
  .header-card-2 img {
    border-radius: 8px 8px 0 0;
    max-height:400px;
    height: 400px;
    width: 100%;
  }
}
.button-principal{
  font-size: 125%;
  margin-top: 10px;
  border-radius: 25px;
  background: #d7d7d7;
  color:black;
  height: 35px;
  width: 120px;
}
</style>