<template>
  <div class="container fixed-top">
    <nav class="navbar navbar-light" style="background-color: #e3f2fd">
      <a class="navbar-brand titulo" href="/">
        <img
          src="../assets/logo4q.png"
          class="d-inline-block align-top img-logo"
          alt="Logo"
        />
        Servicios Gráficos 4Q
      </a>
       <div class="float-right">
 <div class="input-group "> 
  <div class="input-group-append ">
    <button class="btn btn-outline-secondary dropdown-toggle " type="button" data-toggle="dropdown" aria-expanded="false">Menu</button>
    <div class="dropdown-menu dropdown-menu-right ">
      <a class="dropdown-item" href="/">Inicio</a>
      <a class="dropdown-item" @click="espiral">Espiral</a>
      <a class="dropdown-item" @click="papel">Papel</a>      
      <a class="dropdown-item" @click="contacto">contacto</a>
    </div>
  </div>
</div>
</div>
      
    </nav>
 


  </div>
</template>

<script>
export default {
  name: "NavBar",
    methods: {
    papel() {      
      this.$router.push(`/papel`);
    },
    espiral() {      
      this.$router.push(`/espiral`);
    },
    contacto() {      
      this.$router.push(`/contacto`);
    },
  },
};
</script>

<style>
@media (min-width: 300px) {
  .titulo{
    font-size: 85%;
  }
  .img-logo{
    width : 30px;
    height : 30px;
  }
} 
@media (min-width: 576px) {
  .titulo{
    font-size: 125%;
  }
  .img-logo{
    width : 35px;
    height : 35px;
  }
}

@media (min-width: 768px) {
.titulo{
   
    font-size: 150%;
  }
  .img-logo{
    width : 40px;
    height : 40px;
  }

}

@media (min-width: 992px) {
  .titulo{
    font-size: 125%;
  }
  .img-logo{
    width : 40px;
    height : 40px;
  }
}

@media (min-width: 1200px) {
.titulo{
    font-size: 150%;
  }
}
.img-logo{
    margin-right: 20px;
     width : 40px;
    height : 40px;
  }

</style>