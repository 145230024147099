<template>
  <div class="container card-contacto">
    <div class="card">
      <div class="card-header text-center text-titulo">
        <h2>Quiere contactarse con nosotros</h2>
      </div>
      <div class="card-body">
        <div class="text-comunicarse">
          <h3 class="card-text text-center">
            Horario de atención de lunes a viernes : 9:00 a 17:00 .
          </h3>
          <h3 class="card-title text-center">
            Para comunicarse con nosotros puede utilizar los siguientes medios:
          </h3>
          <h4 class="card-text text-center">Whatsapp : + 56 9 9312 9104</h4>
          <h4 class="card-text text-center">
            Correo Electronico : serviciosgraficos4q@gmail.com
          </h4>
        </div>
        <hr />
        <div class="text-considerar">
          <h5>Para considerar :</h5>
          <h5>
            1. Los envíos realizados por Starken o Chilexpress son cancelados
            por el comprador.
          </h5>
          <h5>
            2. Las compras realizadas con envíos por Starken o Chilexpress
            tienen un 15% de descuento en el total de su compra.
          </h5>
          <h5>3. El peso máximo para compras por Mercado Libre es de 20 Kg.</h5>
          <h5>
            4. Las compras realizadas con despacho por Starken o Chilexpress NO
            tienen límite de peso.
          </h5>
          <h5>
            5. El valor de los productos publicados NO tiene incluido el
            despacho.
          </h5>
          <h5>6. Todos los valores publicados son sin IVA.</h5>
          <h5>
            7. Se realizan facturas previo pago del IVA del total de su compra.
          </h5>
          <h5>
            8. El medio de pago por envíos por Starken o Chilexpress es solo con
            transferencia bancaria.
          </h5>
          <h5>
            9. Los pedidos son despachados de lunes a viernes a las 15:00 horas.
          </h5>
          <h5>
            10. Las compras realizadas desde las 14:00 horas en adelante se
            despachan al siguiente día hábil.
          </h5>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
};
</script>

<style>
.card-contacto {
  margin-bottom: 10px;
}
@media (min-width: 300px) {
  .text-considerar h5 {
    font-size: 70%;
  }
  .text-comunicarse h4 {
    font-size: 90%;
  }
  .text-comunicarse h3 {
    font-size: 90%;
  }
  .text-titulo h2 {
    font-size: 100%;
  }
}

@media (min-width: 576px) {
  .text-considerar h5 {
    font-size: 100%;
  }
  .text-comunicarse h4 {
    font-size: 90%;
  }
  .text-comunicarse h3 {
    font-size: 90%;
  }
  .text-titulo h2 {
    font-size: 100%;
  }
}

@media (min-width: 768px) {
  .text-considerar h5 {
    font-size: 100%;
  }
  .text-comunicarse h4 {
    font-size: 90%;
  }
  .text-comunicarse h3 {
    font-size: 90%;
  }
  .text-titulo h2 {
    font-size: 100%;
  }
}

@media (min-width: 992px) {
  .text-considerar h5 {
    font-size: 100%;
  }
  .text-comunicarse h4 {
    font-size: 90%;
  }
  .text-comunicarse h3 {
    font-size: 90%;
  }
  .text-titulo h2 {
    font-size: 100%;
  }
}

@media (min-width: 1200px) {
  .text-considerar h5 {
    font-size: 100%;
  }
  .text-comunicarse h4 {
    font-size: 90%;
  }
  .text-comunicarse h3 {
    font-size: 90%;
  }
  .text-titulo h2 {
    font-size: 100%;
  }
}

@media (min-width: 1300px) {
  .text-considerar h5 {
    font-size: 115%;
  }
  .text-comunicarse h4 {
    font-size: 125%;
  }
  .text-comunicarse h3 {
    font-size: 125%;
  }
  .text-titulo h2 {
    font-size: 125%;
  }
}

@media (min-width: 1400px) {
  .text-considerar h5 {
    font-size: 115%;
  }
  .text-comunicarse h4 {
    font-size: 125%;
  }
  .text-comunicarse h3 {
    font-size: 125%;
  }
  .text-titulo h2 {
    font-size: 125%;
  }
}
</style>