<template>
  <div>
    <CarouselPrincipal />    
    <DetalleProducto />
    <ContactoLink />
  </div>
</template>

<script>
import CarouselPrincipal from "./CarouselPrincipal.vue";
import DetalleProducto from "./DetalleProducto.vue";
import ContactoLink from "./ContactoLink.vue";

export default {
  name: "PaginaInicio",
  components: {
    CarouselPrincipal,
    DetalleProducto,
    ContactoLink,
  },
};
</script>

<style>
</style>